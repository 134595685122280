<!--
 * @Author: your name
 * @Date: 2021-04-27 15:39:40
 * @LastEditTime: 2021-04-27 21:41:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\views\school.vue
-->
<template>
  <div class="allmsgclass">
    <p class="msg">
      <van-icon
        name="arrow-left"
        size="0.34rem"
        style="margin-left: 0.2rem"
        @click="$router.go(-1)"
      />
      <span @click="savemeth" class="wan">保存</span>
    </p>
    <div class="msgclass">
      <div>添加教育经历</div>
      <div @click="$router.push('/school2')" class="themsgclass">
        <div>学校<span class="xh">*</span></div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input readonly="readonly" v-model="school" placeholder="请选择" />
        </div>
      </div>
      <div @click="clickmeth(1)" class="themsgclass">
        <div>学历<span class="xh">*</span></div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input
            readonly="readonly"
            v-model="education_background"
            placeholder="请选择"
          />
        </div>
      </div>
      <div class="themsgclass">
        <div>专业<span class="xh">*</span></div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input v-model="major" placeholder="请填写" />
        </div>
      </div>
      <div @click="clickmeth(3)" class="themsgclass">
        <div>入学时间<span class="xh">*</span>：</div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input
            readonly="readonly"
            v-model="start_time"
            placeholder="请选择"
          />
        </div>
      </div>
      <div @click="clickmeth(4)" class="themsgclass">
        <div>毕业时间<span class="xh">*</span>：</div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input readonly="readonly" v-model="end_time" placeholder="请选择" />
        </div>
      </div>
    </div>
    <pack @packreturn="packreturn" ref="packid"></pack>
    <timees @timereturn="timereturn" ref="timeid"></timees>
  </div>
</template>
<script>
import pack from "../../components/pack";
import timees from "../../components/time";
import education from "../../../util/education"
export default {
  data() {
    return {
      school: window.school,
      education_background: window.education_background,
      major: window.major,
      start_time: window.start_time,
      end_time: window.end_time,

      loack: true,
    };
  },
  components: {
    pack,
    timees,
  },
  watch: {
    major(oldmsg) {
      window.major = oldmsg;
    },
  },
  created() {
    this.school = window.school;
  },
  methods: {
    packreturn(msg, index) {
      if (index == 1) {
        //学历
        this.education_background = window.education_background = msg;
      } else if (index == 2) {
        //专业
        this.major = msg;
      }
    },
    timereturn(msg, index) {
       
      if (index == 3) {
        //入学时间
        this.start_time = window.start_time = msg;
      } else if (index == 4) {
        //毕业时间
        this.end_time = window.end_time = msg;
      }
    },
    clickmeth(index) {
      if (index == 1) {
        this.$refs.packid.packmeth(
          education,
          index
        );
      } else if (index == 2) {
        this.$refs.packid.packmeth(
          education,
          index
        );
      } else if (index == 3) {
        this.$refs.timeid.timemeth(new Date(), index);
      } else if (index == 4) {
        this.$refs.timeid.timemeth(new Date(), index);
      }
    },
    savemeth() {
      if (this.loack == true) {
        this.loack == false;
      } else {
        return;
      }
      if (
        !this.school ||
        !this.education_background ||
        !this.major ||
        !this.start_time ||
        !this.end_time
      ) {
        this.$toast("请完善信息");
        return;
      }
      if (window.schoolobj) {
        window.schoolobj.push({
          school: this.school,
          education_background: this.education_background,
          major: this.major,
          start_time: this.start_time,
          end_time: this.end_time,
        });
      } else {
        window.schoolobj = [];
        window.schoolobj.push({
          school: this.school,
          education_background: this.education_background,
          major: this.major,
          start_time: this.start_time,
          end_time: this.end_time,
        });
      }
      window.school="";
      window.education_background="";
      window.major="";
      window.start_time="";
      window.end_time="";
      this.$router.push("/shareresume2");
    },
  },
};
</script>
<style scoped>
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.xh {
  color: #ff1f1f;
  font-size: 0.3rem;
  margin-left: 0.05rem;
}
input {
  border: none;
}
.themsgclass {
  padding-top: 0.28rem;
  padding-bottom: 0.42rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d6d6d6;
  margin-bottom: 0.27rem;
}
.themsgclass > :first-child {
  font-size: 0.28rem;
  line-height: 0.48rem;
  color: #676767;
}
.themsgclass > :nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.themsgclass > :last-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.msg .wan {
  margin-right: 0.34rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff4800;
}
.msgclass {
  margin: 0.46rem 0.38rem;
}
.msgclass > :first-child {
  font-size: 0.46rem;
  line-height: 0.65rem;
  font-weight: bold;
  margin-bottom: 0.18rem;
}
</style>

<!--
 * @Author: your name
 * @Date: 2021-04-27 18:00:36
 * @LastEditTime: 2021-04-27 18:28:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\components\pack.vue
-->
<template>
  <div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        title=""
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      index: "",
      columns: [],
    };
  },
  methods: {
    packmeth(msg, index) {
      this.columns = msg;
      this.index = index;
      this.show = true;
    },
    onConfirm(value) {
      this.show = false;
      this.$emit("packreturn", value, this.index);
    },
  },
};
</script>
<style scoped>
</style>
